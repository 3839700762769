import React, { useState } from "react";

import { ReactComponent as FacebookIcon } from "../assets/icons/socials/facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "../assets/icons/socials/instagram-icon.svg";
import { ReactComponent as TwitterIcon } from "../assets/icons/socials/twitter-icon.svg";
import { ReactComponent as LinkedInIcon } from "../assets/icons/socials/linkedin-icon.svg";

export default function ContactSection() {
  const [map, setMap] = useState(
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12982.536554430484!2d45.4055939!3d35.5627369!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x392304a05b5701d0!2sHigh%20Smart!5e0!3m2!1sen!2siq!4v1606030571305!5m2!1sen!2siq"
  );
  const handleMapClick = (city) => {
    if (city === "Sulaimaniyah") {
      setMap(
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12982.536554430484!2d45.4055939!3d35.5627369!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x392304a05b5701d0!2sHigh%20Smart!5e0!3m2!1sen!2siq!4v1606030571305!5m2!1sen!2siq"
      );
    } else {
      setMap(
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12880.768626159672!2d43.9640236!3d36.1862083!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9d0e486ec0bd922e!2sHigh%20Smart%20Erbil!5e0!3m2!1sen!2siq!4v1614600925501!5m2!1sen!2siq"
      );
    }
  };
  return (
    <div id="location">
      <div id="contactdiv">
        <div id="contact">
          <h1>Contact Us</h1>
          Emai: <a href="mailto:info@hi-smart.co"> info@hi-smart.co </a>
          Phone: <a href="tel:+964- 770 320 0005">+964 770 320 0005 </a>
          <a href="tel:+964 53 330 0005">+964 750 128 5842 </a>
          <h1>Visit Us</h1>
          <p>
            Our showrooms are open Sunday to Thusrday from 9am to 6pm <br />
            Sulaimani: Rand Gallery, 6th Floor, Office #06. <br />
            Erbil: Italian City 1, Office A22
          </p>
          <h1>Social Media</h1>
          <div className="socials-container">
            <a
              href="https://www.facebook.com/High.Smart.Iraq"
              className="social-container"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon width={16} height={16} />

              <span>Facebook</span>
            </a>
            <a
              href="https://www.instagram.com/high_smart/"
              className="social-container"
              target="_blank"
            >
              <InstagramIcon width={16} height={16} />

              <span>Instagram</span>
            </a>
            <a
              href="https://twitter.com/HighSmart_"
              className="social-container"
              target="_blank"
            >
              <TwitterIcon width={16} height={16} />

              <span>Twitter</span>
            </a>
            <a
              href="https://www.linkedin.com/company/high-smart/"
              className="social-container"
              target="_blank"
            >
              <LinkedInIcon width={16} height={16} />

              <span>Linkedin</span>
            </a>
          </div>
        </div>
        <div id="mapandbutton">
          <div className="mapdiv" id="sulimap">
            <iframe
              title="map and button"
              src={map}
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: "0" }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </div>

          <div id="mapbuttons">
            <button
              class="mapbutton"
              onClick={() => handleMapClick("Sulaimaniyah")}
            >
              Sulaimani
            </button>

            <button class="mapbutton" onClick={() => handleMapClick("Erbil")}>
              Erbil
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
