import React from "react";
import Alexa from "../assets/images/brands/logo-amazon-alexa.jpg";
import Anthem from "../assets/images/brands/logo-anthem.png";
import Araknis from "../assets/images/brands/logo-araknis.png";
import Cisco from "../assets/images/brands/logo-cisco.jpg";
import Control4 from "../assets/images/brands/logo-control4.png";
import Elite from "../assets/images/brands/logo-elite.png";
import Fibaro from "../assets/images/brands/logo-fibaro.jpg";
import Gallo from "../assets/images/brands/logo-gallo.jpg";
import iEast from "../assets/images/brands/logo-ieast.png";
import Jvc from "../assets/images/brands/logo-jvc.png";
import Kaadas from "../assets/images/brands/logo-kaadas.jpg";
import Klipsch from "../assets/images/brands/logo-klipsch.png";
import LitheAudio from "../assets/images/brands/logo-litheaudio.png";
import Luma from "../assets/images/brands/logo-luma.jpg";
import Pakedge from "../assets/images/brands/logo-pakedge.png";
import Paradigm from "../assets/images/brands/logo-paradigm.png";
import Rako from "../assets/images/brands/logo-rako.jpg";
import Somfy from "../assets/images/brands/logo-somfy.png";
import Triad from "../assets/images/brands/logo-triad.jpg";
import Yale from "../assets/images/brands/logo-yale.png";
import Epson from "../assets/images/brands/logo-epson.png";
import Ekinex from "../assets/images/brands/logo-ekinex.jpg";
import Fermax from "../assets/images/brands/logo-fermax.png";
import Doorbird from "../assets/images/brands/logo-doorbird.png";

const brands = [
  Control4,
  Ekinex,
  Kaadas,
  Klipsch,
  Somfy,
  Epson,
  Jvc,
  Alexa,
  Fibaro,
  Cisco,
  Pakedge,
  Araknis,
  Anthem,
  Elite,
  Gallo,
  iEast,
  LitheAudio,
  Luma,
  Paradigm,
  Rako,
  Triad,
  Yale,
  Fermax,
  Doorbird,
];
export default function BrandsSection() {
  return (
    <div id="brands" style={{ scrollMarginTop: "100px" }}>
      <div class="sectiontitle">
        <h1 class="heading">
          Working with the most trust worthy global brands
        </h1>
      </div>
      <div id="logo_grid">
        {brands.map((brand) => {
          return (
            <div
              class="brand"
              style={{ backgroundImage: `url(${brand})` }}
            ></div>
          );
        })}
      </div>
    </div>
  );
}
