import React from "react";
import Solution from "./Solution";
import Lights from "../assets/images/gallery/lights.jpg";
import Audio from "../assets/images/gallery/audio.png";
import Comfort from "../assets/images/gallery/comfort.png";
import FibaroKeypad from "../assets/images/gallery/Walli.jpg";
import Security from "../assets/images/gallery/motion.jpeg";
import Cinema from "../assets/images/gallery/cinema.jpg";
import PhoneBG from "../assets/images/gallery/Smartphone.png";
import House from "../assets/images/gallery/house.jpg";
import Doors from "../assets/images/gallery/doors.jpg";
import Answer from "../assets/images/gallery/answer.jpg";
import Sensor from "../assets/images/gallery/Sensor.png";
import HistoryBG from "../assets/images/gallery/HistoryBG.png";
import SmokeBG from "../assets/images/gallery/Smoke.png";
import LightingOptions from "../assets/images/gallery/LightingOptions.jpg";
import Dimmer from "../assets/images/gallery/Dimmer.png";
import CentralizedLighting from "../assets/images/gallery/centralizedlighting.jpg";
import Gallo from "../assets/images/gallery/gallo.jpg";
import Projector from "../assets/images/gallery/projector.jpg";
import ACPhone from "../assets/images/gallery/ACPhone.jpg";
import Snow from "../assets/images/gallery/snow.jpg";
import Sleep from "../assets/images/gallery/thermo.png";
import VSSL from "../assets/images/gallery/vssl.png";
import C4Cinema from "../assets/images/gallery/c4cinema.jpg";
import Shutters from "../assets/images/gallery/shutters.jpg";
import Kaadas from "../assets/images/gallery/kaadas.jpg";
import LiveView from "../assets/images/gallery/liveview.jpg";
import Reciever from "../assets/images/gallery/reciever.jpg";

import { ReactComponent as Options } from "../assets/icons/solutions/OPTIONS.svg";
import { ReactComponent as Settings } from "../assets/icons/solutions/SETTINGS.svg";
import { ReactComponent as Airdrop } from "../assets/icons/solutions/AIRDROP.svg";
import { ReactComponent as Alarm } from "../assets/icons/solutions/ALARM.svg";
import { ReactComponent as Amplifier } from "../assets/icons/solutions/AMPLIFIER.svg";
import { ReactComponent as Centralized } from "../assets/icons/solutions/CENTRALIZED.svg";
import { ReactComponent as Datashow } from "../assets/icons/solutions/DATASHOW.svg";
import { ReactComponent as History } from "../assets/icons/solutions/HISTORY.svg";
import { ReactComponent as Mood } from "../assets/icons/solutions/MOOD.svg";
import { ReactComponent as Motion } from "../assets/icons/solutions/MOTION.svg";
import { ReactComponent as Phone } from "../assets/icons/solutions/PHONE.svg";
import { ReactComponent as Roller } from "../assets/icons/solutions/ROLLER.svg";
import { ReactComponent as Speaker } from "../assets/icons/solutions/SPEAKER.svg";
import { ReactComponent as Weather } from "../assets/icons/solutions/WEATHER.svg";
import { ReactComponent as Wifi } from "../assets/icons/solutions/WIFI.svg";
import { ReactComponent as Secure } from "../assets/icons/solutions/LOCK.svg";
import { ReactComponent as NoKeys } from "../assets/icons/solutions/NOKEYS.svg";

// Fixed height at first
// On hover it expands with transition
// Bunch of buttons inside that changes (Title, Description, Background Image and select state);

// <Solution  />

const solutions = [
  {
    title: "Smart Lighting",
    description:
      "Smart lighting adds elegance, ambiance, convenience and energy efficiency to your home. Make your house appear occupied while you are away. Raise or dim any light in the room or the entire house with a single touch. Automate your lighting to respond to your schedule without any touch at all. It’s not just smart, it’s brilliant.",
    backgroundImage: Lights,
    subCategories: [
      {
        id: "Centralized Lighting",
        title: "Centralized Lighting",
        description:
          "Perfect for homes that are in the new-build stages or homes undergoing a remodel project, lighting wires are run to a centralized location—such as a utility closet—eliminating the need for banks of switches and, instead, replacing control of individual or sets of light fixtures with stylish and elegant keypads.",
        backgroundImage: CentralizedLighting,
        icon: Centralized,
      },
      {
        id: "Wireless Lighting",
        title: "Wireless Lighting",
        description:
          "In areas where you would prefer not to cut open walls for cable running, wireless dimmers, switches, and keypads make for an ideal control interface. Wireless lighting is often favorable for remodel projects and can be more cost-effective than retrofitting a wired system.",
        backgroundImage: FibaroKeypad,
        icon: Wifi,
      },
      {
        id: "Options",
        title: "Options",
        description:
          "You are not limited to your light switches anymore. Control your lighting with your phone, keypads, voice control, or a wall mounted tablet.",
        backgroundImage: LightingOptions,
        icon: Options,
      },

      {
        id: "Mood Control",
        title: "Mood Control",
        description:
          "Turn everything on when it’s time to work, close the curtains and set the light levels to low when it’s time to relax. With a combination of our light switches, dimmers, and curtains, you can get the exact mood you want by simply   pressing the scene you want.          ",
        backgroundImage: Dimmer,
        icon: Mood,
      },
      {
        id: "Automation",
        title: "Automatic Scenes",
        description:
          "Your curtains will close and your yard lights will turn on when it's night time. Your lights will turn on automatically when you enter the room, they can change color based on the weather. Your possibilities are endless when you have Smart Lighting.",
        backgroundImage: House,
        icon: Settings,
      },
    ],
  },

  {
    title: "Audio Systems ",
    description:
      "Whether it’s because you love throwing parties in your home or if you just like having music playing around your house, High Smart is the best bet if you want a premium audio system in your home. Depending on your rooms, desired zones, and your budget we will plan out a design for you and present you options that suit you best.",
    backgroundImage: Audio,

    subCategories: [
      {
        id: "Speakers",
        title: "Speakers",
        description:
          "Say goodbye to cheap speakers from the market and say hello to high fidelity audio quality. We have partnered with global brands of the highest end to make sure the music you play in your home sounds amazing.          ",
        backgroundImage: Gallo,
        icon: Speaker,
      },
      {
        id: "Amplifiers",
        title: "Amplifiers",
        description:
          "Most people know that they need good speakers for good audio, but what's just as important is having high quality amplifiers that are appropriate for your home. With our top of the line amplifiers you can get the highest quality possible out of your speakers, and we provide options for every type of building so you can get something suitable and flexible for the amount of rooms and audio zones you desire.",
        backgroundImage: VSSL,
        icon: Amplifier,
      },
      {
        id: "Cast",
        title: "Cast Your Audio",
        description:
          "The days of using an AUX cable or Bluetooth are long gone. Our sound systems support Airplay and Google Cast, letting you play the music you want anywhere from the house without interruption",
        backgroundImage: PhoneBG,
        icon: Airdrop,
      },
    ],
  },
  {
    title: "Comfort ",
    description:
      "Control all of your air conditioning and heating systems from the same app instead of having to manage your split AC’s, fans, floor heaters, etc individually.",
    backgroundImage: Comfort,

    subCategories: [
      {
        id: "Phone",
        title: "No Remotes - Just Your Phone",
        description:
          "You no longer need to run around every room of the house to turn on and turn off your multiple air conditioning devices. Control them all from your phone even if you are outside the house.          ",
        backgroundImage: ACPhone,
        icon: Phone,
      },
      {
        id: "Automated",
        title: "Automated",
        description:
          "Turn off all of your HVAC when your work begins, turn them back on when you're back in the house, turn off the unnecessary rooms when you go to sleep. You no longer need to worry about your power bill and micromanage your devices, just set a schedule for them and they'll automatically work.",
        backgroundImage: Sleep,
        icon: Settings,
      },
      {
        id: "Adaptive",
        title: "Adaptive To The Weather",
        description:
          "You can set your HVAC system to automatically provide your home with the climate you enjoy even when the seasons change. Just tell your installers how you prefer your rooms to be and your smart home will take care of everything",
        backgroundImage: Snow,
        icon: Weather,
      },
    ],
  },
  {
    title: "Security and Safety ",
    description:
      "Have complete control over your property, with our sensors and CCTV systems you can have all the information you need about your home when you’re not there. ",
    backgroundImage: Security,

    subCategories: [
      {
        id: "Motion",
        title: "Motion Sensors",
        description:
          "With a few carefully placed motion sensors and contact sensors you can be instantly notified when there is an intruder in your home and your sirens and emergency light scenes will instantly trigger, scaring away any potential robber.      ",
        backgroundImage: Sensor,
        icon: Motion,
      },
      {
        id: "Safety",
        title: "Safety",
        description:
          "A safety system is one of the most important things to have in your home which is why we bring only the best and most reliable solutions. With our Smoke, CO2, Heat and Flood sensors you can always be sure that your home is safe at all times - Even if you’re not there.",
        backgroundImage: SmokeBG,
        icon: Alarm,
      },
      {
        id: "History",
        title: "History Record",
        description:
          "Not only do our devices notify you in case of an event happening, they also keep a log of every time there has been activity around your house, so you can always be sure that everything is under control.",
        backgroundImage: HistoryBG,
        icon: History,
      },
      {
        id: "Shutters",
        title: "Roller Shutters",
        description:
          "Keep your windows safe from intruders by installing smart shutters. No need to manually pull up and down a hefty piece of metal, just set a schedule for them or simply press a button on your phone.",
        backgroundImage: Shutters,
        icon: Roller,
      },
    ],
  },
  {
    title: "Home Cinema ",
    description:
      "Having a TV is nice but what’s nicer is having a complete home cinema in your room - whether it’s a set of 4 speakers for your living room or a full room dedicated to movies in your home with a projector, screen, and 7.1 audio, High Smart has you covered.",
    backgroundImage: Cinema,

    subCategories: [
      {
        id: "Projectors",
        title: "Projectors",
        description:
          "Watching a movie or the latest football match on a TV is nice, but having a projector is nicer - We work closely with Epson and JVC to bring the highest quality home cinema projectors to the region.",
        backgroundImage: Projector,
        icon: Datashow,
      },

      {
        id: "AV",
        title: "Robust AV Proccessors",
        description:
          "The heart of any home cinema is the audio/ video processor. We will help you pick one according to the needs of your home depending on how many speakers, subwoofers, input devices (DVD Player, Console, Apple TV, etc), and output devices (Projector, TVs) you have.",
        backgroundImage: Reciever,
        icon: Amplifier,
      },
      {
        id: "Automated",
        title: "Automated",
        description:
          "Once you have a home cinema room it can be daunting to turn every device on one by one, with our smart home technology you can turn on your screen, projector, audio system and video device with the press of one button",
        backgroundImage: C4Cinema,
        icon: Settings,
      },
    ],
  },
  {
    title: "Intercom and Doors ",
    description:
      "Have complete control over your property, with our sensors and CCTV systems you can have all the information you need about your home when you’re not there. ",
    backgroundImage: Doors,

    subCategories: [
      {
        id: "Answer and open when you’re away",
        title: "Answer anywhere",
        description:
          "Know who is at your door at all times and open it with the touch of a button.",
        backgroundImage: Answer,
        icon: Phone,
      },

      {
        id: "No More Keys",
        title: "No More Keys",
        description:
          "With our smart doors you do not have to worry about if you locked the door when you left the house and worry about who has the keys and who doesn’t, instead open your door with a pin code, fingerprint, keycard, or your phone.",
        backgroundImage: Kaadas,
        icon: NoKeys,
      },
      {
        id: "	Secure        ",
        title: "	Secure        ",
        description:
          "Our outdoor intercoms integrate with your CCTV station to get you a live view of your front door, and our doors keep a record of when they opened and warn you when someone tries to tamper with them or break them - so you can always be sure your home is safe.",
        backgroundImage: LiveView,
        icon: Secure,
      },
    ],
  },
];

export default function SolutionsSection() {
  return (
    <div id="solutions" style={{ scrollMarginTop: "100px" }}>
      <div class="sectiontitle">
        <h1 class="heading">
          Providing you with high end smart home solutions
        </h1>
      </div>

      <div className="solutions-container">
        {solutions.map((solution) => (
          <Solution details={solution} />
        ))}
      </div>
    </div>
  );
}
