import "./App.css";
import Navigation from "./components/Navigation";
import HeroSection from "./components/HeroSection";
import ServicesSection from "./components/ServicesSection";
import BrandsSection from "./components/BrandsSection";
/*import ExperienceSection from "./components/ExperienceSection";*/
import ContactSection from "./components/ContactSection";
import SolutionsSection from "./components/SolutionsSection";
import { Helmet, HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>High Smart</title>
        <meta
          name="description"
          content="The number one provider, dealer and installer for all of your smart home and home automation needs in Iraq"
        />
      </Helmet>

      <div className="App">
        <Navigation />
        <HeroSection />
        <SolutionsSection />
        <ServicesSection />
        <BrandsSection />
        <ContactSection />
      </div>
    </HelmetProvider>
  );
}

export default App;
/*      <ExperienceSection />*/
