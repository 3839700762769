import React from "react";

export default function ServiceCard(props) {
  return (
    <div className="service-card">
      <img class="serviceicon" src={props.image} alt="Service Icon" />
      <h2 class="heading">{props.title}</h2>
      <p>{props.description}</p>
    </div>
  );
}
