import React, { useState } from "react";

export default function Solution({ details }) {
  const { title, description, subCategories } = details;

  const [selectedItem, setSelectedItem] = useState(details);

  //   `url(${
  //     selectedItem === ""
  //       ? details.backgroundImage
  //       : selectedItem.backgroundImage
  //   })`

  const condition =
    selectedItem.id === ""
      ? `url${details.backgroundImage}`
      : `url${selectedItem.backgroundImage}`;

  console.log(condition);
  return (
    <div
      class="grow"
      style={
        selectedItem
          ? {
              backgroundImage:
                selectedItem.id === ""
                  ? `url(${details.backgroundImage})`
                  : `url(${selectedItem.backgroundImage})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              position: "relative",
              textAlign: "center",
              color: "white",
              margin: "auto",
              backgroundColor: "rgba(0,0,0,.3)",
              backgroundBlendMode: "darken",
            }
          : {}
      }
    >
      {selectedItem && details.title && (
        <h1>{selectedItem.id === "" ? details.title : selectedItem.title}</h1>
      )}

      {selectedItem && details.title && (
        <p>
          {selectedItem.id === ""
            ? details.description
            : selectedItem.description}
        </p>
      )}

      <div className="subcategoryButton-container">
        {details.subCategories.map((subCategory) => {
          console.log(subCategory.key === selectedItem.key);

          return (
            <button
              className={`articlebutton ${
                subCategory.id === selectedItem.id && "selectedbutton"
              }`}
              onClick={() => setSelectedItem(subCategory)}
            >
              {/* {subCategory.icon.render()} */}

              {subCategory.icon && <subCategory.icon width={64} height={64} />}
            </button>
          );
        })}
      </div>
    </div>
  );
}
