import React, { useState } from "react";
import Logo from "../assets/images/logo.png";
import { ReactComponent as MenuIcon } from "../assets/icons/menu-icon.svg";

export default function Navigation() {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  return (
    <nav class={`topnav ${isMenuVisible ? "responsive" : ""}`} id="myTopnav">
      <a href="#">
        <img
          alt="High Smart Logo"
          src={Logo}
          height="30px"
          width="30px"
          style={{ marginTop: "5px" }}
        />
      </a>
      <a href="#" onClick={() => setIsMenuVisible(!isMenuVisible)}>
        Home
      </a>
      <a href="#solutions" onClick={() => setIsMenuVisible(!isMenuVisible)}>
        Solutions
      </a>
      <a href="#servicestitle" onClick={() => setIsMenuVisible(!isMenuVisible)}>
        Services
      </a>
      <a href="#brands" onClick={() => setIsMenuVisible(!isMenuVisible)}>
        Brands
      </a>

      <a href="#location" onClick={() => setIsMenuVisible(!isMenuVisible)}>
        Location
      </a>

      <a
        href="javascript:void(0);"
        class="icon"
        onClick={() => setIsMenuVisible(!isMenuVisible)}
      >
        <MenuIcon width={24} height={24} />
      </a>
    </nav>
  );
}
/*  <a href="#aboutus">About Us</a>*/
