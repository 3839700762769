import React, { useState } from "react";
import HS from "../assets/images/hs1.png";
import { ReactComponent as PlayIcon } from "../assets/icons/play-icon.svg";
import Modal from "react-modal";

const customStyles = {
  content: {
    width: "60%",
    top: "55%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0",
    overflow: "hidden",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    zIndex: "9999",
  },
};

export default function HeroSection() {
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div id="homediv">
      <picture>
        <source srcset={HS} />
        <img id="homelogo" src="hs.png" alt="High Smart Logo" />
      </picture>
      {/* 
      <button id="myBtn" onClick={openModal}>
        <span>Watch Video</span>

        <PlayIcon style={{ marginLeft: "10px" }} width={24} height={24} />
      </button> 
      */}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Video Player"
      >
        <iframe
          title="High Smart Video"
          width="100%"
          height="100%"
          src="https://www.facebook.com/plugins/video.php?height=317&href=https%3A%2F%2Fwww.facebook.com%2FHigh.Smart.Iraq%2Fvideos%2F361793555290641%2F&show_text=false&width=560&t=0"
        ></iframe>
      </Modal>
    </div>
  );
}
