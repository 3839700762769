import React from "react";
import ServiceCard from "./ServiceCard";
import Consultation from "../assets/images/services/consultation.png";
import Customization from "../assets/images/services/customization.png";
import Design from "../assets/images/services/design.png";
import Documentation from "../assets/images/services/documentation.png";
import Implementation from "../assets/images/services/implementation.png";
import Maintenance from "../assets/images/services/maintenance.png";

const services = [
  {
    title: "CONSULTATION",
    description:
      "Pre-meeting and discussion about everything that should be included in your smart home.",
    image: Consultation,
  },
  {
    title: "SYSTEM DOCUMENTATION",
    description:
      "We provide the documents that describes your smart home's requirements, capabilities, and specifications.",
    image: Documentation,
  },
  {
    title: "DESIGN & ENGINEERING",
    description:
      "Comprehensive and thoughtful system design and engineering up front leads to the best balance of functionality, reliability and aesthetics in the end result.",
    image: Design,
  },
  {
    title: "IMPLEMENTATION",
    description:
      "Our technicians plan and implement all of your cabling needs in a new construction or when upgrading an existing space.",
    image: Implementation,
  },
  {
    title: "CUSTOMIZATION",
    description:
      "Your home is completely in your hands - we will provide you with multiple options depending on your taste for every system.",
    image: Customization,
  },
  {
    title: "MAINTENANCE & SUPPORT",
    description:
      "High Smart's technical team is available on 8-5 hours to diagnose, update, replace, and repair any issues that can happen.",
    image: Maintenance,
  },
];
export default function ServicesSection() {
  return (
    <div style={{ padding: "0 2rem" }}>
      <div
        class="sectiontitle"
        id="servicestitle"
        style={{ scrollMarginTop: "100px" }}
      >
        <h1 class="heading">Dedicated to great service and customer support</h1>
      </div>

      <div className="services">
        {services.map((service) => {
          return (
            <ServiceCard
              title={service.title}
              description={service.description}
              image={service.image}
            />
          );
        })}
      </div>
    </div>
  );
}
